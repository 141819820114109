import { parse } from 'qs';
import { localeManager } from '@pcid/locale-utils';
import getFooterLinks from '../get-footer-links';

export const DUMMY_RP = 'relying-party';

export const getRelyingPartyFromRouter = ({ asPath = '/' } = {}) => parse(asPath.split('?')[1]).rp;

export const BrandPrimaryColor = {
	BLACK: 'black',
	WHITE: 'white',
	PINK: 'pink',
	RED: 'red',
	LIGHTBLUE: 'lightblue',
	ORANGE: 'orange',
};

export const RelyingParty = {
	ACCOUNT_MANAGEMENT: 'account-management',
	JOE_FRESH_CA: 'joefresh-ca',
	JOE_FRESH_US: 'joefresh-us',
	JOE_FRESH_IOS: 'joefresh-ios',
	BEAUTY_SDM: 'beauty-sdm',
	BEAUTY_PHARMAPRIX: 'beaute-pharmaprix',
	DRX: 'pharmacy-sdm',
	PHARMACY_VPHX: 'pharmacy-vphx',
	PCO_SDM: 'pco-sdm',
	PCO_PHARMAPRIX: 'pco-pharmaprix',
	SDM: 'sdm',
	SDM_IOS: 'sdm-ios',
	SDM_ANDROID: 'sdm-android',
	NEW_ANDROID: 'new-android',
	PCID_ANDROID_DEMO: 'pcid_android_demo',
	PCID_ANDROID_PILOT: 'pcid_android_pilot',
	PCID_IOS_DEMO: 'pcid_ios_demo0',
	PCID_IOS_PILOT: 'pcid_ios_pilot0',
	PCINSIDERS: 'pcinsiders',
	PC_EXPRESS_WEB: 'pcexpress',
	PC_EXPRESS_IOS: 'pcexpress-ios',
	PC_EXPRESS_ANDROID: 'pcexpress-android',
	PCID_M2: 'pcid-m2',
	PCID_M3: 'pcid-m3',
	PCO_WEB: 'pco',
	PCO_IOS: 'pco-ios',
	PCO_ANDROID: 'pco-android',
	PCO_MINIAPP_MP: 'pco-miniapp-mp',
	PCO_PCX_MP: 'pco-pcx-mp',
	PCO_PCX_RAPID: 'pco-pcx-rapid',
	LOBLAWS_WEB: 'loblaw',
	RCSS_WEB: 'superstore',
	NO_FRILLS: 'nofrills',
	WHOLESALE_CLUB: 'wholesaleclub',
	ZEHRS: 'zehrs',
	PROVIGO: 'provigo',
	MAXI: 'maxi',
	FORTINOS: 'fortinos',
	YOUR_INDEPENDENT_GROCER: 'independent',
	EXTRA_FOODS: 'extrafoods',
	VALUMART: 'valumart',
	LOBLAWS_CITY_MARKET: 'loblawcitymarket',
	DOMINION: 'dominion',
	ATLANTIC_SUPERSTORE: 'rass',
	INDEPENDENT_CITY_MARKET: 'independentcitymarket',
	INSTACART: 'instacart',
	INSTACART_IOS: 'instacart-ios',
	WEBSAVER: 'websaver',
	MARKETPLACE: 'marketplace-sellers',
	ECOLE_CULINAIRE_PC: 'ecole-culinaire-pc',
	DIETITIAN: 'dietitian',
	PC_HEALTH_WEB: 'pc-health-web',
	PC_HEALTH_IOS: 'pc-health-ios',
	PC_HEALTH_ANDROID: 'pc-health-android',
	PC_HEALTH_PHARMACY: 'pharmacy-pch',
	PC_CHEF: 'pc-chef',
	SHOPPERS_PHOTO: 'shoppers-photo',
	ENT_SCHEDULING: 'ent-scheduling',
	PHARMAPRIX_PHOTO: 'pharmaprix-photo',
	REAL_CANADIAN_LIQUOR_STORE: 'real-canadian-liquor-store',
	WELLWISE: 'wellwise',
	ESSO_EXTRA: 'esso-extra',
	ESSO_SPEEDPASS_ANDROID: 'esso-speedpass-android',
	ESSO_SPEEDPASS_IOS: 'esso-speedpass-ios',
	RAPID: 'rapid',
	PC_FINANCIAL_ANDROID: 'pcf-android',
	PC_FINANCIAL_IOS: 'pcf-ios',
	PC_FINANCIAL_WEB: 'pcf-web',
};

// Groups common filenames for RPs with shared config files
export const getRelyingPartyFile = (relyingParty) => {
	if (!Object.values(RelyingParty).includes(relyingParty)) {
		// Prevents cases where rp value is not defined as a config module
		return false;
	}
	switch (relyingParty) {
		case RelyingParty.PCO_WEB:
		case RelyingParty.PCO_IOS:
		case RelyingParty.PCO_ANDROID:
			return 'pco';
		case RelyingParty.PC_EXPRESS_IOS:
		case RelyingParty.PC_EXPRESS_ANDROID:
			return 'pcexpress-mobile';
		case RelyingParty.PCID_M2:
		case RelyingParty.PCID_M3:
		case RelyingParty.LOBLAWS_WEB:
			return 'loblaw';
		case RelyingParty.INSTACART:
		case RelyingParty.INSTACART_IOS:
			return 'instacart';
		case RelyingParty.SDM_ANDROID:
		case RelyingParty.SDM_IOS:
			return 'sdm-mobile';
		default:
			return relyingParty;
	}
};

// StoreName and HomeURL are used by the RelyingPartyList component, abstracted out of config files
export const getRelyingPartyConstants = (relyingParty) => {
	const language = localeManager.getLanguage();
	switch (relyingParty) {
		case RelyingParty.JOE_FRESH_CA:
		case RelyingParty.JOE_FRESH_US:
		case RelyingParty.JOE_FRESH_IOS:
			return {
				storeName: 'common.joeFresh',
				homeURL: 'https://www.joefresh.com',
			};
		case RelyingParty.BEAUTY_SDM:
			return {
				storeName: 'common.beautyBoutique',
				homeURL: 'https://beauty.shoppersdrugmart.ca',
			};
		case RelyingParty.BEAUTY_PHARMAPRIX:
		case RelyingParty.PCO_PHARMAPRIX:
			return {
				storeName: 'common.pharmaprix',
				homeURL: 'https://beaute.pharmaprix.ca/',
			};
		case RelyingParty.DRX:
			return {
				storeName: 'common.sdmOnlinePharmacy',
				homeURL: 'https://mypharmacy.shoppersdrugmart.ca',
			};
		case RelyingParty.PHARMACY_VPHX:
			return {
				storeName: 'common.pharmaprix',
				homeURL: 'https://mondossier.pharmaprix.ca',
			};
		case RelyingParty.PCO_SDM:
		case RelyingParty.SDM:
			return {
				storeName: 'common.sdm',
				homeURL: 'https://www1.shoppersdrugmart.ca',
			};
		case RelyingParty.SDM_IOS:
		case RelyingParty.SDM_ANDROID:
			return {
				storeName: 'common.sdm',
				homeURL: 'https://beauty.shoppersdrugmart.ca',
			};
		case RelyingParty.PCINSIDERS:
			return {
				storeName: 'common.pcInsiders',
				homeURL: `https://www.pcinsiders.ca/${language}`,
			};
		case RelyingParty.PC_EXPRESS_WEB:
		case RelyingParty.PC_EXPRESS_IOS:
		case RelyingParty.PC_EXPRESS_ANDROID:
			return {
				storeName: 'common.pcExpress',
				homeURL: 'https://www.pcexpress.ca/',
			};
		case RelyingParty.PCO_WEB:
		case RelyingParty.PCO_IOS:
		case RelyingParty.PCO_ANDROID:
		case RelyingParty.WEBSAVER:
		case RelyingParty.ESSO_SPEEDPASS_IOS:
		case RelyingParty.ESSO_SPEEDPASS_ANDROID:
		case RelyingParty.NEW_ANDROID:
		case RelyingParty.PCID_ANDROID_DEMO:
		case RelyingParty.PCID_ANDROID_PILOT:
		case RelyingParty.PCID_IOS_DEMO:
		case RelyingParty.PCID_IOS_PILOT:
			return {
				storeName: 'common.pco',
				homeURL: 'https://www.pcoptimum.ca',
			};
		case RelyingParty.RCSS_WEB:
			return {
				storeName: 'common.rcss',
				homeURL: 'https://www.realcanadiansuperstore.ca',
			};
		case RelyingParty.NO_FRILLS:
			return {
				storeName: 'common.noFrills',
				homeURL: 'https://www.nofrills.ca',
			};
		case RelyingParty.WHOLESALE_CLUB:
			return {
				storeName: 'common.wholesaleClub',
				homeURL: 'https://www.wholesaleclub.ca',
			};
		case RelyingParty.ZEHRS:
			return {
				storeName: 'common.zehrs',
				homeURL: 'https://www.zehrs.ca',
			};
		case RelyingParty.PROVIGO:
			return {
				storeName: 'common.provigo',
				homeURL: 'https://www.provigo.ca',
			};
		case RelyingParty.MAXI:
			return {
				storeName: 'common.maxi',
				homeURL: 'https://www.maxi.ca',
			};
		case RelyingParty.FORTINOS:
			return {
				storeName: 'common.fortinos',
				homeURL: 'https://www.fortinos.ca',
			};
		case RelyingParty.YOUR_INDEPENDENT_GROCER:
			return {
				storeName: 'common.yourIndependentGrocer',
				homeURL: 'https://www.yourindependentgrocer.ca',
			};
		case RelyingParty.EXTRA_FOODS:
			return {
				storeName: 'common.extraFoods',
				homeURL: 'https://www.extrafoods.ca',
			};
		case RelyingParty.VALUMART:
			return {
				storeName: 'common.valumart',
				homeURL: 'https://www.valumart.ca',
			};
		case RelyingParty.LOBLAWS_CITY_MARKET:
			return {
				storeName: 'common.loblawsCityMarket',
				homeURL: 'https://www.loblaws.ca',
			};
		case RelyingParty.DOMINION:
			return {
				storeName: 'common.dominion',
				homeURL: 'https://www.newfoundlandgrocerystores.ca',
			};
		case RelyingParty.ATLANTIC_SUPERSTORE:
			return {
				storeName: 'common.atlanticSuperstore',
				homeURL: 'https://www.atlanticsuperstore.ca',
			};
		case RelyingParty.INDEPENDENT_CITY_MARKET:
			return {
				storeName: 'common.independentCityMarket',
				homeURL: 'https://www.independentcitymarket.ca',
			};
		case RelyingParty.INSTACART:
		case RelyingParty.INSTACART_IOS:
			return {
				storeName: 'common.instacart',
				homeURL: 'https://delivery.pcexpress.ca/',
			};
		case RelyingParty.ACCOUNT_MANAGEMENT:
			return {
				storeName: 'common.accountManagement',
				homeURL: 'https://myaccount.pcid.ca',
			};
		case RelyingParty.MARKETPLACE:
			return {
				storeName: 'common.marketplace',
			};
		case RelyingParty.ECOLE_CULINAIRE_PC:
			return {
				storeName: 'common.ecoleCulinaire',
				homeURL: 'https://www.ecoleculinairepc.ca',
			};
		case RelyingParty.DIETITIAN:
			return {
				storeName: 'common.dietitian',
				homeURL: language === 'en' ? 'https://dietitianwebinars.ca/' : 'https://dietitianwebinars.ca/fr/?presentation_lang=fr',
			};
		case RelyingParty.PC_HEALTH_WEB:
		case RelyingParty.PC_HEALTH_IOS:
		case RelyingParty.PC_HEALTH_ANDROID:
			return {
				storeName: 'common.pcHealth',
				homeURL: language === 'en' ? 'https://www.pchealth.ca' : 'https://www.pchealth.ca/fr',
			};
		case RelyingParty.PC_HEALTH_PHARMACY:
			return {
				storeName: 'common.pharmacyPCH',
				homeURL: `https://mypharmacy.pchealth.ca/${language}`,
			};
		case RelyingParty.PC_CHEF:
			return {
				storeName: 'common.pcChef',
				homeURL: 'https://pcchef.ca',
			};
		case RelyingParty.SHOPPERS_PHOTO:
			return {
				storeName: 'common.shoppersPhoto',
			};
		case RelyingParty.ENT_SCHEDULING:
			return {
				storeName: 'common.entScheduling',
			};
		case RelyingParty.PHARMAPRIX_PHOTO:
			return {
				storeName: 'common.pharmaprixPhoto',
			};
		case RelyingParty.REAL_CANADIAN_LIQUOR_STORE:
			return {
				homeURL: 'https://shop.realcanadianliquorstore.ca/',
				storeName: 'common.realCanadianLiquorStore',
			};
		case RelyingParty.WELLWISE:
			return {
				storeName: 'common.wellwise',
			};
		case RelyingParty.ESSO_EXTRA:
			return {
				homeURL: {
					dev: `https://essoextra-cr.exchangesolutions.com/pages/member_home.aspx?lang=${language}-CA#/home`,
					prod: `https://www.essoextra.com/pages/member_home.aspx?lang=${language}-CA#/home`,
				},
				storeName: 'common.essoExtra',
			};
		case RelyingParty.PC_FINANCIAL_ANDROID:
		case RelyingParty.PC_FINANCIAL_IOS:
		case RelyingParty.PC_FINANCIAL_WEB:
			return {
				storeName: 'common.pcFinancial',
			};
		case RelyingParty.PCID_M2:
		case RelyingParty.PCID_M3:
		case RelyingParty.LOBLAWS_WEB:
		default:
			return {
				storeName: 'common.loblaws',
				homeURL: 'https://www.loblaws.ca',
			};
	}
};

export const getHomeURL = (relyingParty) => (getRelyingPartyConstants(relyingParty).homeURL);
export const getStoreName = (relyingParty) => (getRelyingPartyConstants(relyingParty).storeName);

// RP Config Defaults
export const relyingPartyDefaults = {
	storeName: '',
	homeURL: '',
	forgotPasswordURL: '/forgot-password',
	createAccountURL: '/create-account',
	helpURL: getFooterLinks(localeManager.getLocale(), 'loblaw'),
	termsURL: '',
	updateEmail: true,
	color: '',
	sso: false,
	collectName: false,
	showDoNotAskMeMfaCheckbox: true,
};

export const pcExpressMigrationMessage = {
	type: 'warning',
	message: 'rp.pcexpress.migrationmessage',
};
