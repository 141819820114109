import React from 'react';
import { localeCodes, localeManager } from '@pcid/locale-utils';

import LoadingAnimation from '../loading-animation';

const LoadingPage = () => {
	const loadingText = localeManager.getLocale() === localeCodes.EN ? 'Loading' : 'Chargement';
	return (
		<div className="loading-page">
			<span>{loadingText}</span>
			<LoadingAnimation />
		</div>
	);
};

export default LoadingPage;
